s<template>
    <div>
        <p class="text-orange">
            PS：已发起变更的合同(正在审批中或者是草稿状态)，将不在当前列表内显示，请到变更列表中编辑查看！
        </p>
        <Row class="p-t-10">
            <i-col span="6">选择变更合同：</i-col>
            <i-col span="18">
                <i-select v-model="chosseContractId" filterable clearable>
                    <i-option v-for="contract in list" :key="contract.id" :value="contract.id">{{contract.name}}</i-option>
                </i-select>
            </i-col>
        </Row>
        <div class="p-t-10" v-if="chosseContractId && chosseContractId!==0">
            <Row>
                <i-col span="6">合同编号：</i-col>
                <i-col span="18">{{contract.code}}</i-col>
            </Row>
            <Row>
                <i-col span="6">客户：</i-col>
                <i-col span="18">{{contract.advertiserName}}</i-col>
            </Row>
            <Row>
                <i-col span="6">总额：</i-col>
                <i-col span="18">{{formatMoney(contract.amount)}}</i-col>
            </Row>
            <Row>
                <i-col span="6">合同开始日期：</i-col>
                <i-col span="18">{{contract.startDate}}</i-col>
            </Row>
            <Row>
                <i-col span="6">合同结束日期：</i-col>
                <i-col span="18">{{contract.endDate}}</i-col>
            </Row>
        </div>
        <Row class="text-right p-t-10">
          <Button type="success" v-show="!isSubmit" @click="handleCreateContractChange">创建合同变更</Button>
        </Row>
    </div>
</template>

<script>
import { getContractPage } from '@/api/scp/contract'
import { getContractChangingVersionId } from '@/api/scp/contractchange'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      list: [],
      query: { pageNumber: 1, pageSize: 100 },
      chosseContractId: 0,
      contract: {}
    }
  },
  methods: {
    getPage () {
      this.query.userId = this.$store.getters.token.userInfo.userId
      this.query.statusList = JSON.stringify([5])
      this.query.isChanging = false

      getContractPage(this.query).then(res => {
        this.list = res.list
      })
    },
    handleCreateContractChange () {
      getContractChangingVersionId({ contractId: this.chosseContractId }).then(res => {
        if (res && !res.errcode) {
          this.chosseContractId = 0
          this.getPage()
          this.$Notice.success({ desc: '合同变更创建成功！' })
          this.$emit('on-create', { id: res.contractId, versionId: res.versionId })
        }
      })
    },
    formatMoney (money) {
      return toMoney(money || 0)
    }
  },
  computed: {
    isSubmit () {
      return this.chosseContractId === 0 || this.chosseContractId === undefined
    },
    updateTime () {
      return this.$store.state.contract.beginUpdate
    }
  },
  created () {
    this.getPage()
  },
  watch: {
    chosseContractId (val) {
      if (val && val !== 0) {
        this.contract = this.list.find(x => x.id === val)
      } else {
        this.contract = {}
      }
    },
    updateTime () {
      this.getPage()
    }
  }
}
</script>
